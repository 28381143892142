<template>
    <div class="repairrecord">
        <NavBar :list="[{name:'维修记录'}]" name="报修管理"></NavBar>
        <div class="public-box">
            <el-form :inline="true">
                <el-form-item>
                    <MyInput v-model="formInline.merchants_name" placeholder="输入报修人姓名">
                        <template slot="pre">报修人姓名:</template>
                    </MyInput>
                </el-form-item>
                <el-form-item>
                    <MyInput v-model="formInline.merchants_mobile" placeholder="输入报修人姓名">
                        <template slot="pre">报修人电话:</template>
                    </MyInput>
                </el-form-item>
                <el-form-item>
                    <MyButton class="offset" left @click="search">
                        搜索
                        <template slot="preImage">
                            <img src="../../unit/img/search.png" alt />
                        </template>
                    </MyButton>
                </el-form-item>
            </el-form>
            <!-- table -->
            <div class="public-table">
                <el-table :data="tableList" :header-cell-style="{
                  'text-align': 'center',
                  background: 'rgb(245, 245, 245)',
                }" :cell-style="{ 'text-align': 'center' }">
                    <el-table-column label="报修设备" prop="repair_type"></el-table-column>
                    <el-table-column label="报修时间" prop="create_at"></el-table-column>
                    <el-table-column label="报修人姓名" prop="merchants_name"></el-table-column>
                    <el-table-column label="联系电话" prop="merchants_mobile"></el-table-column>
                    <el-table-column label="问题描述" prop="repair_details"></el-table-column>
                    <el-table-column label="问题图片" prop="">
                        <template #default="{row}">
                            <el-image v-if="row.repair_images.length > 0" :preview-src-list="row.repair_images"
                                title="查看" :src="require('@/assets/img/icon/xianshi.png')" />
                            <span v-else>暂无图片</span>
                        </template>
                    </el-table-column>
                  <el-table-column label="处理后图片" prop="">
                    <template #default="{row}">
                      <el-image v-if="row.acceptance_images.length > 0" :preview-src-list="row.acceptance_images"
                                title="查看" :src="require('@/assets/img/icon/xianshi.png')" />
                      <span v-else>暂无图片</span>
                    </template>
                  </el-table-column>
                </el-table>
            </div>
            <div class="public-page">
                <el-pagination :total="pageTotal" next-text="下一页" prev-text="上一页" :current-page="formInline.pageNum"
                    :page-size="formInline.pageSize" :current-change="search" layout="total,prev,pager,next">
                </el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
import * as common from "../../plugin/common";

export default {
    data() {
        return {
            formInline: {
                pageNum: 1,
                pageSize: 10,
                repair_status: 2
            },
            tableList: [],
            pageTotal: 0,
        }
    },
    created() {
        this.getList();
    },
    methods: {
        getList() {
            this.tableList = [];
            this.$request.HttpGet("/repair/list", this.formInline)
                .then(res => {
                    if (res.data && res.data.list) {
                        this.tableList = res.data.list;
                        this.tableList.forEach(item => {
                          item.repair_images = common.getImg(item.repair_images)
                          item.acceptance_images = common.getImg(item.acceptance_images)
                        })
                        this.pageTotal = res.data.total;
                    }
                })
        },
        search(page = 1) {
            this.formInline.pageNum = page
            this.getList();
        }
    }
}
</script>
<style scoped lang="scss">
.el-image{
  width: 16px;
}
</style>
